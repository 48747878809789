import styled from 'styled-components';
import {orange} from '../../../style/colors';

export const Notification = styled.div`
  position: absolute;
  width: 33%;
  margin: auto;
  left: 0;
  right: 0;
  top: 24px;
  background-color: white;
  padding: 8px;
  padding-left: 50px;
  border-radius: 8px;

  /* smaller devices */
  @media (max-width: 1200px) {
    font-size: 12px;
  }

  &:before {
    display: block;
    position: absolute;
    left: 20px;
    top: 8px;
    content: '!';
    border-radius: 50%;
    border: 1px solid ${orange};
    width: 20px;
    height: 20px;
    line-height: 22px;
    text-align: center;
    color: ${orange};
    font-weight: 600;
  }
`;
